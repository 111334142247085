import React from "react";
import { Link, graphql } from "gatsby";
import Layout from "../components/layout";
// import Image from "../components/image"
import SEO from "../components/seo";
const _ = require("lodash");

const AllTags = ({
	data: {
		allMarkdownRemark: { group },
	},
}) => {
	return (
		<Layout>
			<SEO title="Tags" />

			<div>
				<h1>Tags</h1>
				<ul>
					{group.filter(tag=>tag.totalCount > 1).map(tag => (
						<li key={tag.fieldValue}>
							<Link to={`/tag/${_.kebabCase(tag.fieldValue)}/`}>
								{tag.fieldValue} 
							</Link>
							{" "}
							({tag.totalCount})
						</li>
					))}
				</ul>
			</div>
		</Layout >
	);
};

export default AllTags;

export const pageQuery = graphql`
    query {
        allMarkdownRemark(limit: 2000) {
            group(field: frontmatter___tags) {
                fieldValue
                totalCount
            }
        }
    }
`;
